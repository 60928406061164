<template>
  <component :is="isCurrentComponent"></component>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    PhoneDescription: () => import('@/components/services/onloPhone/PhoneDescription.vue'),
    PhoneConnected: () => import('@/components/services/onloPhone/PhoneConnected.vue')
  },

  computed: {
    ...mapGetters({ servicesData: 'connectedServices/servicesData' }),

    isCurrentComponent() {
      return this.servicesData.OnloPhone === 2 ? 'PhoneConnected' : 'PhoneDescription';
    }
  }
};
</script>

<style></style>
